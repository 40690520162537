import React from "react"

import Layout from "../components/layout"

import Seamless from "../components/Seamless"

import SEO from "../components/seo"
import "../css/pages/wisdom-for-wisdom.scss"
import "../css/pages/wisdom.scss"

const WisdomApplicationPage = () => {
  let language = "en"

  return (
    <Layout pageTitle="procedure-page" language={language}>
      <SEO
        lang={language}
        title="Apply Here For The Wisdom For Wisdom Program"
        description="Follow the Wisdom For Wisdom steps to apply"
      />

      <div className="joshua-tree-content program-page-wisdom">
        <div className="columns wisdom-application top-section white-back has-text-centered">
          <div className="column">
            <h1>Wisdom For Wisdom Application</h1>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default WisdomApplicationPage
